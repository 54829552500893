/*jshint esversion: 11 */
// redefine console.log
console.log = function() {};

import ('./../css/index.scss');

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCookie, faCircleXmark, faLink, faChevronDown, faChevronUp, faPhone, faLocationDot, faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faCookie, faCircleXmark, faLink, faChevronDown, faChevronUp, faPhone, faLocationDot, faBars);

window.addEventListener('scroll', function() {
    let toTop = document.getElementById('toTopMain');
    let elementSee = document.getElementById('teamCommon');
    if (elementSee !== null) {
        if (elementSee.offsetTop > this.scrollY) {
            toTop.style.display = 'none';
        } else {
            toTop.style.display = 'flex';
        }
    }

});

